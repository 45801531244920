import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/account": [~6,[2]],
		"/account/card": [~7,[2]],
		"/account/fees": [~8,[2]],
		"/account/loans": [~9,[2]],
		"/account/pickup": [~10,[2]],
		"/account/requests": [~11,[2]],
		"/admin": [~12],
		"/bookmarks": [~13],
		"/bookmarks/[listname]": [~14],
		"/demo": [15],
		"/demo/citation": [16],
		"/demo/paraglide": [17],
		"/example": [~18],
		"/example/bento": [19],
		"/example/list": [20],
		"/example/notes": [21],
		"/example/sentry-example": [22],
		"/example/test": [23],
		"/example/test/email": [24],
		"/example/test/skeleton": [25],
		"/forms": [~26],
		"/forms/av": [~27],
		"/forms/request": [~28],
		"/history": [~29],
		"/journals": [30],
		"/new": [31,[3]],
		"/new/books": [33,[3]],
		"/new/databases": [34,[3]],
		"/new/journals": [35,[3]],
		"/new/[nel]": [32,[3]],
		"/poweredby": [36],
		"/search": [37],
		"/search/1": [38],
		"/search/new": [39],
		"/search/options": [40],
		"/subscriptions": [~41],
		"/taxonomy": [42,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';